// import React, { useState } from 'react';
// import axios from 'axios';
// import { toast } from 'react-toastify';
// import config from '../config';

// const CertificationGenerator: React.FC = () => {
//   const [certificationName, setCertificationName] = useState('');
//   const [isGenerating, setIsGenerating] = useState(false);

//   const handleGenerate = async () => {
//     if (!certificationName) {
//       toast.error('Please enter a certification name');
//       return;
//     }

//     setIsGenerating(true);
//     try {
//       const response = await axios.post(
//         `${config.API_URL}/quiz/generate_certification`,
//         { certification_name: certificationName },
//         { withCredentials: true }
//       );
//       toast.success('Certification generated successfully');
//       setCertificationName('');
//     } catch (error) {
//       toast.error('Failed to generate certification');
//       console.error('Error generating certification:', error);
//     } finally {
//       setIsGenerating(false);
//     }
//   };

//   return (
//     <div className="certification-generator">
//       <h2>Generate New Certification</h2>
//       <div className="input-group">
//         <input
//           type="text"
//           value={certificationName}
//           onChange={(e) => setCertificationName(e.target.value)}
//           placeholder="Enter certification name"
//           disabled={isGenerating}
//         />
//         <button onClick={handleGenerate} disabled={isGenerating}>
//           {isGenerating ? 'Generating...' : 'Generate'}
//         </button>
//       </div>
//     </div>
//   );
// };

// export default CertificationGenerator;
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../api/axios";
import axios from "axios"; // Keep for type checking
import { toast } from "react-toastify";

const CertificationGenerator: React.FC = () => {
  const [certificationName, setCertificationName] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);
  const navigate = useNavigate();

  const handleGenerate = async () => {
    if (!certificationName) {
      toast.error("Please enter a certification name");
      return;
    }

    setIsGenerating(true);
    try {
      const response = await api.post("/quiz/generate_certification", {
        certification_name: certificationName,
      });

      toast.success("Certification generated successfully");
      setCertificationName("");
    } catch (error) {
      console.error("Error generating certification:", error);
      // Check if it's an authentication error
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        navigate("/login");
      } else {
        toast.error("Failed to generate certification");
      }
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4">Generate New Certification</h2>
      <div className="space-y-4">
        <input
          type="text"
          value={certificationName}
          onChange={(e) => setCertificationName(e.target.value)}
          placeholder="Enter certification name"
          disabled={isGenerating}
          className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <button
          onClick={handleGenerate}
          disabled={isGenerating}
          className={`w-full p-2 text-white rounded-md ${
            isGenerating
              ? "bg-gray-400 cursor-not-allowed"
              : "bg-blue-500 hover:bg-blue-600"
          }`}
        >
          {isGenerating ? "Generating..." : "Generate"}
        </button>
      </div>
    </div>
  );
};

export default CertificationGenerator;
