import React, { useState, useEffect, useRef } from "react";
import {
  Menu,
  ChevronDown,
  LogOut,
  Zap,
  Heart,
  CheckCircle,
  User,
} from "lucide-react";
import { useAuth } from "./AuthContext";
import api from "../api/axios"; // Use the configured api instance instead of axios directly
import config from "../config";
import { Link, useNavigate } from "react-router-dom"; // Add useNavigate
import axios from "axios"; // Add this import for type checking

interface UserStats {
  xp: number;
  lives: number;
  streak: number;
  timeUntilNextLife: number | null;
}

interface ActiveCertification {
  id: number;
  name: string;
}

const Header: React.FC = () => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { isAuthenticated, setIsAuthenticated } = useAuth();
  const [userStats, setUserStats] = useState<UserStats | null>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const [activeCertification, setActiveCertification] =
    useState<ActiveCertification | null>(null);
  const [error, setError] = useState<string | null>(null);

  const fetchUserStats = async () => {
    if (!isAuthenticated) return;

    try {
      const response = await api.get<UserStats>("/user/stats");
      setUserStats(response.data);
      setError(null);
    } catch (err) {
      if (axios.isAxiosError(err) && err.response?.status === 401) {
        setIsAuthenticated(false);
        navigate("/login");
      } else {
        console.error("Error fetching user stats:", err);
        setError("Failed to fetch user stats");
      }
    }
  };

  const fetchActiveCertification = async () => {
    if (!isAuthenticated) return;

    try {
      const response = await api.get<ActiveCertification>(
        "/user/active-certification",
      );
      setActiveCertification(response.data);
    } catch (err) {
      if (axios.isAxiosError(err) && err.response?.status === 401) {
        setIsAuthenticated(false);
        navigate("/login");
      } else {
        console.error("Error fetching active certification:", err);
      }
    }
  };

  useEffect(() => {
    fetchUserStats();
    const intervalId = setInterval(fetchUserStats, 60000);
    return () => clearInterval(intervalId);
  }, [isAuthenticated]);

  useEffect(() => {
    fetchActiveCertification();
  }, [isAuthenticated]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleLogout = async () => {
    try {
      await api.post("/auth/logout");
      setIsAuthenticated(false);
      setUserStats(null);
      setActiveCertification(null);
      navigate("/");
    } catch (err) {
      console.error("Logout error:", err);
    }
  };

  const formatTimeUntilNextLife = (seconds: number | null) => {
    if (seconds === null) return "Full";
    if (seconds <= 0) return "Now";
    const minutes = Math.ceil(seconds / 60);
    return `${minutes}m`;
  };

  return (
    <header className="bg-white shadow-md">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center py-3">
          <div className="flex items-center space-x-4">
            <div className="relative" ref={menuRef}>
              <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="flex items-center text-blue-600 hover:text-blue-800"
              >
                <Menu size={24} />
                <ChevronDown size={20} className="ml-1" />
              </button>
              {isMenuOpen && (
                <nav className="absolute top-full left-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
                  <ul className="py-1">
                    <li>
                      <Link
                        to="/"
                        className="block px-4 py-2 hover:bg-blue-100"
                      >
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/certifications"
                        className="text-blue-600 hover:text-blue-800"
                      >
                        All Certifications
                      </Link>
                    </li>

                    {activeCertification ? (
                      <li>
                        <Link
                          to={`/cert/${activeCertification.id}`}
                          className="block px-4 py-2 hover:bg-blue-100"
                        >
                          Study Domain
                        </Link>
                      </li>
                    ) : (
                      <li>
                        <span className="block px-4 py-2 text-gray-400 cursor-not-allowed">
                          Study Domain
                        </span>
                      </li>
                    )}
                    {isAuthenticated && (
                      <>
                        <li>
                          <Link
                            to="/profile"
                            className="block px-4 py-2 hover:bg-blue-100"
                          >
                            My Profile
                          </Link>
                        </li>
                        <li>
                          <button
                            onClick={handleLogout}
                            className="w-full text-left px-4 py-2 hover:bg-blue-100 text-red-600"
                          >
                            <LogOut size={20} className="inline mr-2" />
                            Log Out
                          </button>
                        </li>
                      </>
                    )}
                  </ul>
                </nav>
              )}
            </div>
            <Link
              to="/"
              className="text-2xl font-bold text-blue-600 hover:text-blue-800"
            >
              Certit
            </Link>
          </div>
          <div className="flex items-center space-x-4">
            {isAuthenticated && userStats && (
              <div className="hidden sm:flex items-center space-x-4">
                <div className="flex items-center">
                  <Zap className="text-yellow-500 mr-1" />
                  <span>{userStats.xp} XP</span>
                </div>
                <div className="flex items-center">
                  <Heart className="text-red-500 mr-1" />
                  <span>
                    {userStats.lives} (
                    {formatTimeUntilNextLife(userStats.timeUntilNextLife)})
                  </span>
                </div>
                <div className="flex items-center">
                  <CheckCircle className="text-green-500 mr-1" />
                  <span>{userStats.streak}</span>
                </div>
                <Link
                  to="/profile"
                  className="flex items-center text-blue-600 hover:text-blue-800"
                >
                  <User size={20} className="mr-1" />
                  <span>Profile</span>
                </Link>
              </div>
            )}
            {!isAuthenticated && (
              <div className="flex space-x-2">
                <Link
                  to="/login"
                  className="px-3 py-1 text-sm border border-blue-600 text-blue-600 rounded hover:bg-blue-600 hover:text-white"
                >
                  Sign In
                </Link>
                <Link
                  to="/register"
                  className="px-3 py-1 text-sm bg-blue-600 text-white rounded hover:bg-blue-700"
                >
                  Sign Up
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
