import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useNavigate, Link } from "react-router-dom";
import api from "../api/axios";
import config from "../config";
import Header from "./Header";
import Footer from "./Footer";
import {
  FaTrophy,
  FaChartBar,
  FaPencilAlt,
  FaExchangeAlt,
  FaDice,
  FaMedal,
} from "react-icons/fa";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
} from "recharts";
import { toast } from "react-toastify";
import { format } from "date-fns";
import axios from "axios";

// Add this interface to define the structure of an achievement
interface Achievement {
  id: number;
  name: string;
  description: string;
  icon: string;
  achievement_type: string;
  earned_at: string;
}

interface Certification {
  id: number;
  name: string;
}

interface UserData {
  id: number;
  username: string;
  email: string;
  is_pro: boolean;
  total_quizzes_taken: number;
  average_percentage_score: number;
  total_quizzes_created: number;
  user_mode: string;
}

interface UserPerformance {
  accuracy: number;
  correct_answers: number;
  domain_coverage: number;
  domain_id: number;
  domain_name: string;
  questions_attempted: number;
  total_questions: number;
  total_quiz_attempts: number;
  unique_questions_attempted: number;
}

interface UserStats {
  total_quizzes_taken: number;
  highest_score: number;
  lowest_score: number;
  average_score: number;
  total_quizzes_created: number;
  recent_quizzes: Array<{
    quiz_id: number;
    quiz_title: string;
    score: number;
    date_taken: string;
    category: string;
  }>;
}

interface PerformanceData {
  performance: Record<string, UserPerformance>;
  user_mode: string;
}

interface FormattedPerformanceData {
  subject: string;
  accuracy: number;
  coverage: number;
  fullMark: number;
  domain_id: number;
}

interface CustomTickProps {
  x: number;
  y: number;
  payload: {
    value: string;
  };
  index: number;
  data: FormattedPerformanceData[];
}

const CustomTick: React.FC<CustomTickProps> = ({ x, y, payload, data }) => {
  const navigate = useNavigate(); // Add this at the top of your component
  const [showTooltip, setShowTooltip] = useState(false);
  const maxWidth = 100;
  const fontSize = 14;
  const lineHeight = 16;

  const wrapText = (text: string, maxWidth: number): string[] => {
    const words = text.split(" ");
    const lines: string[] = [];
    let currentLine = words[0];

    for (let i = 1; i < words.length; i++) {
      const word = words[i];
      const width = getTextWidth(
        currentLine + " " + word,
        `${fontSize}px Arial`,
      );
      if (width < maxWidth) {
        currentLine += " " + word;
      } else {
        lines.push(currentLine);
        currentLine = word;
      }
    }
    lines.push(currentLine);
    return lines;
  };

  const getTextWidth = (text: string, font: string): number => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    if (context) {
      context.font = font;
      return context.measureText(text).width;
    }
    return 0;
  };

  const lines = wrapText(payload.value, maxWidth);

  const handleClick = (e: React.MouseEvent) => {
    const dataItem = data.find((item) => item.subject === payload.value);
    const domainId = dataItem?.domain_id;
    if (domainId !== undefined) {
      navigate(`/domain/${domainId}`); // Use navigate instead of window.location.href
    } else {
      console.log("Domain ID is undefined, not redirecting");
    }
  };

  return (
    <g
      transform={`translate(${x},${y})`}
      onClick={handleClick}
      style={{ cursor: "pointer" }}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {lines.map((line, index) => (
        <text
          key={index}
          x={0}
          y={index * lineHeight}
          dy={16}
          textAnchor="middle"
          fill="#333"
          fontSize={fontSize}
        >
          {line}
        </text>
      ))}
      {showTooltip && lines.length > 1 && (
        <foreignObject
          x={-maxWidth / 2}
          y={-lines.length * lineHeight - 10}
          width={maxWidth}
          height={lines.length * lineHeight + 20}
        >
          <div
            style={{
              background: "rgba(255, 255, 255, 0.9)",
              border: "1px solid #ccc",
              borderRadius: "4px",
              padding: "5px",
              fontSize: `${fontSize}px`,
              lineHeight: `${lineHeight}px`,
              textAlign: "center",
            }}
          >
            {payload.value}
          </div>
        </foreignObject>
      )}
    </g>
  );
};

const getNextQuizDomain = (
  userPerformance: UserPerformance[],
): number | null => {
  if (!userPerformance || userPerformance.length === 0) {
    return null;
  }
  const sortedDomains = [...userPerformance].sort((a, b) => {
    return a.total_quiz_attempts - b.total_quiz_attempts;
  });
  const selectedDomain = sortedDomains[0];
  return selectedDomain.domain_id;
};

const calculateAverageScore = (stats: UserStats | null): string => {
  if (!stats || !stats.recent_quizzes || stats.recent_quizzes.length === 0) {
    return "N/A";
  }
  const validQuizzes = stats.recent_quizzes.filter((quiz) => quiz.score > 0);
  if (validQuizzes.length === 0) {
    return "N/A";
  }
  const totalScore = validQuizzes.reduce((sum, quiz) => sum + quiz.score, 0);
  const averageScore = totalScore / validQuizzes.length;
  return `${averageScore.toFixed(2)}%`;
};

const QuizMasterUserDashboard: React.FC = () => {
  const [userData, setUserData] = useState<UserData | null>(null);
  const [userStats, setUserStats] = useState<UserStats | null>(null);
  const [userPerformance, setUserPerformance] = useState<
    Record<string, UserPerformance>
  >({});
  const [userMode, setUserMode] = useState<string>("cert_mode");
  const [activeCertification, setActiveCertification] = useState<{
    id: number;
    name: string;
  } | null>(null);
  const [certifications, setCertifications] = useState<Certification[]>([]);
  const [achievements, setAchievements] = useState<Achievement[]>([]);

  const fetchUserAchievements = useCallback(async () => {
    try {
      const response = await axios.get(`${config.API_URL}/user/achievements`, {
        withCredentials: true,
      });
      setAchievements(response.data);
    } catch (error) {
      console.error("Error fetching user achievements:", error);
    }
  }, []);

  const nextQuizDomainId = useMemo(() => {
    return getNextQuizDomain(Object.values(userPerformance));
  }, [userPerformance]);

  const fetchActiveCertification = useCallback(async () => {
    try {
      const response = await axios.get(
        `${config.API_URL}/user/active-certification`,
        {
          withCredentials: true,
        },
      );
      setActiveCertification(response.data);
    } catch (error) {
      console.error("Error fetching active certification:", error);
      setActiveCertification(null);
    }
  }, []);

  const getWelcomeMessage = useCallback(() => {
    if (!userData) return "Welcome!";
    if (userMode === "cert_mode") {
      return "Studying for:";
    } else {
      return `${userData.username}, you're in free study mode!`;
    }
  }, [userData, userMode]);

  const fetchUserData = useCallback(async () => {
    try {
      const response = await axios.get(`${config.API_URL}/user/profile`, {
        withCredentials: true,
      });
      setUserData(response.data);
      setUserMode(response.data.user_mode);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  }, []);

  const fetchUserStats = useCallback(async () => {
    try {
      const response = await axios.get(`${config.API_URL}/user/stats`, {
        withCredentials: true,
      });
      setUserStats(response.data);
    } catch (error) {
      console.error("Error fetching user stats:", error);
    }
  }, []);

  const fetchUserPerformance = useCallback(async () => {
    try {
      const response = await axios.get(`${config.API_URL}/user/performance`, {
        withCredentials: true,
      });
      setUserPerformance(response.data.performance);
      setUserMode(response.data.user_mode);
      console.log("User performance data:", response.data.performance);
    } catch (error) {
      console.error("Error fetching user performance:", error);
    }
  }, []);

  const fetchCertifications = async () => {
    try {
      const response = await axios.get(
        `${config.API_URL}/quiz/certifications`,
        {
          withCredentials: true,
        },
      );
      setCertifications(response.data);
    } catch (error) {
      console.error("Error fetching certifications:", error);
    }
  };

  useEffect(() => {
    fetchUserData();
    fetchUserStats();
    fetchActiveCertification();
    fetchUserPerformance();
    fetchCertifications();
    fetchUserAchievements();
  }, [
    fetchUserData,
    fetchUserStats,
    fetchActiveCertification,
    fetchUserPerformance,
    fetchUserAchievements,
  ]);

  const handleCertificationChange = async (certId: number) => {
    try {
      await axios.post(
        `${config.API_URL}/user/set-active-cert`,
        {
          cert_id: certId,
        },
        {
          withCredentials: true,
        },
      );
      setActiveCertification({
        id: certId,
        name: certifications.find((cert) => cert.id === certId)?.name || "",
      });

      await fetchUserPerformance();

      toast.success("Certification updated successfully");
    } catch (error) {
      console.error("Error changing certification:", error);
      toast.error("Failed to change certification");
    }
  };

  const formatPerformanceData = useCallback(
    (data: PerformanceData): FormattedPerformanceData[] => {
      return Object.values(data.performance).map((item) => ({
        subject: item.domain_name,
        accuracy: item.accuracy,
        coverage: item.domain_coverage,
        fullMark: 100,
        domain_id: item.domain_id,
      }));
    },
    [],
  );

  const renderCertificationAndQuizButtons = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          gap: "1rem",
          marginTop: "0.5rem",
        }}
      >
        {userMode === "cert_mode" && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "white",
              borderRadius: "4px",
              padding: "0.25rem 0.5rem",
            }}
          >
            <span
              style={{
                color: "#4A90E2",
                fontWeight: "bold",
                fontSize: "0.9rem",
                marginRight: "0.5rem",
              }}
            >
              Studying for:
            </span>
            <select
              value={activeCertification?.id || ""}
              onChange={(e) =>
                handleCertificationChange(Number(e.target.value))
              }
              style={{
                padding: "0.25rem",
                border: "none",
                backgroundColor: "transparent",
                color: "#4A90E2",
                fontWeight: "bold",
                fontSize: "0.9rem",
                appearance: "none",
                cursor: "pointer",
              }}
            >
              <option value="">Select certification</option>
              {certifications.map((cert) => (
                <option key={cert.id} value={cert.id}>
                  {cert.name}
                </option>
              ))}
            </select>
            <FaExchangeAlt
              style={{
                color: "#4A90E2",
                marginLeft: "0.5rem",
                fontSize: "0.9rem",
              }}
            />
          </div>
        )}
        {userMode === "cert_mode" && activeCertification && (
          <Link
            to={`/quiz/take?domain_id=${nextQuizDomainId}&num_questions=10`}
            style={{
              backgroundColor: "#4CAF50",
              color: "white",
              fontWeight: "bold",
              padding: "0.5rem 1rem",
              borderRadius: "5px",
              textDecoration: "none",
              transition: "background-color 0.3s ease",
              fontSize: "0.9rem",
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
            }}
            onMouseEnter={(e) =>
              (e.currentTarget.style.backgroundColor = "#45a049")
            }
            onMouseLeave={(e) =>
              (e.currentTarget.style.backgroundColor = "#4CAF50")
            }
          >
            Next Quiz <FaDice />
          </Link>
        )}
        {userMode !== "cert_mode" && (
          <Link
            to="/quiz/generate/free_study?num_questions=10"
            style={{
              backgroundColor: "#4CAF50",
              color: "white",
              fontWeight: "bold",
              padding: "0.5rem 1rem",
              borderRadius: "5px",
              textDecoration: "none",
              transition: "background-color 0.3s ease",
              fontSize: "0.9rem",
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
            }}
            onMouseEnter={(e) =>
              (e.currentTarget.style.backgroundColor = "#45a049")
            }
            onMouseLeave={(e) =>
              (e.currentTarget.style.backgroundColor = "#4CAF50")
            }
          >
            Free Study Quiz <FaDice />
          </Link>
        )}
      </div>
    );
  };

  const renderAchievements = () => (
    <div style={{ display: "flex", flexDirection: "column", gap: "0.75rem" }}>
      {achievements.map((achievement) => (
        <div
          key={achievement.id}
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0.75rem",
            backgroundColor: "#f5f5f5",
            borderRadius: "8px",
          }}
        >
          <FaMedal
            style={{
              marginRight: "1rem",
              color: "#F5A623",
              fontSize: "1.5rem",
            }}
          />
          <div>
            <strong style={{ display: "block", marginBottom: "0.25rem" }}>
              {achievement.name}
            </strong>
            <span style={{ fontSize: "0.9rem", color: "#666" }}>
              {achievement.description}
            </span>
            <div
              style={{
                fontSize: "0.8rem",
                color: "#888",
                marginTop: "0.25rem",
              }}
            >
              Earned on {format(new Date(achievement.earned_at), "MMM d, yyyy")}
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div
      className="flex flex-col min-h-screen"
      style={{
        fontFamily: "Open Sans, sans-serif",
        color: "#333333",
        backgroundColor: "#F8F8F8",
      }}
    >
      <Header />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          backgroundColor: "#4A90E2",
          padding: "0.75rem",
          marginBottom: "1rem",
        }}
      >
        {renderCertificationAndQuizButtons()}
      </div>
      <main
        className="flex-grow"
        style={{ maxWidth: "1200px", margin: "2rem auto", padding: "0 1rem" }}
      >
        {userData && userData.is_pro && (
          <div
            style={{
              backgroundColor: "#F5A623",
              color: "white",
              padding: "0.5rem",
              borderRadius: "4px",
              marginBottom: "2rem",
              display: "block",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            Pro User
          </div>
        )}
        {Object.keys(userPerformance).length > 0 && (
          <div style={{ width: "100%", height: 400, marginBottom: "1rem" }}>
            <ResponsiveContainer width="100%" height="100%">
              {(() => {
                const formattedData = formatPerformanceData({
                  performance: userPerformance,
                  user_mode: userMode,
                });
                return (
                  <RadarChart
                    cx="50%"
                    cy="40%"
                    outerRadius="80%"
                    data={formattedData}
                  >
                    <PolarGrid />
                    <PolarAngleAxis
                      dataKey="subject"
                      tick={(props: CustomTickProps) => (
                        <CustomTick {...props} data={formattedData} />
                      )}
                    />
                    <PolarRadiusAxis angle={30} domain={[0, 100]} />
                    <Radar
                      name="Accuracy"
                      dataKey="accuracy"
                      stroke="#8884d8"
                      fill="#8884d8"
                      fillOpacity={0.6}
                    />
                    <Radar
                      name="Coverage"
                      dataKey="coverage"
                      stroke="#82ca9d"
                      fill="#82ca9d"
                      fillOpacity={0.6}
                    />
                  </RadarChart>
                );
              })()}
            </ResponsiveContainer>
          </div>
        )}
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
            gap: "2rem",
            marginBottom: "2rem",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              padding: "1.5rem",
              borderRadius: "8px",
              boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
            }}
          >
            <h3
              style={{
                fontFamily: "Montserrat, sans-serif",
                color: "#50E3C2",
                marginTop: 0,
                marginBottom: "1.5rem",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FaChartBar style={{ marginRight: "0.5rem" }} /> Quiz Stats &
              Achievements
            </h3>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "1rem",
                marginBottom: "1.5rem",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  padding: "1rem",
                  backgroundColor: "#f0f8ff",
                  borderRadius: "8px",
                }}
              >
                <strong
                  style={{
                    display: "block",
                    marginBottom: "0.5rem",
                    color: "#4A90E2",
                  }}
                >
                  Quizzes Taken
                </strong>
                <span style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                  {userData?.total_quizzes_taken || 0}
                </span>
              </div>
              <div
                style={{
                  textAlign: "center",
                  padding: "1rem",
                  backgroundColor: "#fff0f5",
                  borderRadius: "8px",
                }}
              >
                <strong
                  style={{
                    display: "block",
                    marginBottom: "0.5rem",
                    color: "#FF69B4",
                  }}
                >
                  Average Score
                </strong>
                <span style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                  {calculateAverageScore(userStats)}
                </span>
              </div>
            </div>
            <h4
              style={{
                fontFamily: "Montserrat, sans-serif",
                color: "#F5A623",
                marginBottom: "1rem",
              }}
            >
              Achievements
            </h4>
            {renderAchievements()}
          </div>
          <div
            style={{
              backgroundColor: "white",
              padding: "1.5rem",
              borderRadius: "8px",
              boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
            }}
          >
            <h3
              style={{
                fontFamily: "Montserrat, sans-serif",
                color: "#4A90E2",
                marginTop: 0,
              }}
            >
              Recent Activity
            </h3>
            <ul style={{ listStyle: "none", padding: 0 }}>
              {userStats?.recent_quizzes.map((quiz, index) => (
                <li
                  key={index}
                  style={{
                    padding: "0.5rem 0",
                    borderBottom:
                      index < userStats.recent_quizzes.length - 1
                        ? "1px solid #eee"
                        : "none",
                  }}
                >
                  <Link
                    to={`/quiz/${quiz.quiz_id}/results`}
                    style={{
                      textDecoration: "none",
                      color: "#4A90E2",
                      display: "block",
                      padding: "0.5rem",
                      borderRadius: "4px",
                      transition: "background-color 0.3s ease",
                    }}
                    onMouseEnter={(e) =>
                      (e.currentTarget.style.backgroundColor = "#f0f8ff")
                    }
                    onMouseLeave={(e) =>
                      (e.currentTarget.style.backgroundColor = "transparent")
                    }
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <strong>{quiz.quiz_title}</strong>
                        <div style={{ fontSize: "0.8rem", color: "#888" }}>
                          {quiz.category === "Free Study" ? (
                            <span style={{ color: "#32CD32" }}>Free Study</span>
                          ) : (
                            quiz.category
                          )}
                        </div>
                      </div>
                      <div style={{ textAlign: "right" }}>
                        <div>{quiz.score.toFixed(2)}%</div>
                        <div style={{ fontSize: "0.8rem", color: "#888" }}>
                          {new Date(quiz.date_taken).toLocaleDateString()}
                        </div>
                      </div>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default QuizMasterUserDashboard;
