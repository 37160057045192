import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import api from "../api/axios";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import styled from "styled-components";
import config from "../config";
import ReactMarkdown from "react-markdown";
import Header from "./Header";
import {
  FaBook,
  FaLightbulb,
  FaSearch,
  FaChevronDown,
  FaChevronUp,
  FaChevronLeft,
  FaChevronRight,
  FaQuestionCircle,
  FaClipboardCheck,
} from "react-icons/fa";

interface KeyConcept {
  id: number;
  name: string;
  description: string;
}

interface Domain {
  id: number;
  name: string;
  description: string;
  intro_markdown: string;
}

const DomainKeyConceptsWrapper = styled.div`
  font-family: "Open Sans", sans-serif;
  color: #333333;
  background-color: #f8f8f8;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Main = styled.main`
  max-width: 1200px;
  margin: 1rem auto;
  padding: 0 1rem;
  flex: 1;
`;

const ContentGrid = styled.div<{ showConcepts: boolean }>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.showConcepts ? "300px 1fr" : "1fr"};
  gap: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Card = styled.div`
  background-color: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const ContentWrapper = styled.div`
  background-color: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 1rem;
  gap: 0.5rem;
`;

const CardTitle = styled.h2`
  font-family: "Montserrat", sans-serif;
  color: #4a90e2;
  margin-top: 0;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.5rem;
  }
`;

const ConceptList = styled.ul`
  list-style: none;
  padding: 0;
`;

const ConceptListItem = styled.li`
  margin-bottom: 0.5rem;
`;

const ConceptButton = styled.button`
  background: none;
  border: none;
  color: #4a90e2;
  cursor: pointer;
  text-align: left;
  font-size: 1rem;
  padding: 0.5rem;
  width: 100%;
  border-radius: 4px;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;

  &:hover,
  &:focus,
  &[aria-pressed="true"] {
    background-color: #4a90e2;
    color: white;
  }
`;

const ActionButton = styled.button`
  font-family: "Montserrat", sans-serif;
  color: white;
  font-size: 0.875rem;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  &:hover,
  &:focus {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  svg {
    margin-right: 0.5rem;
  }
`;

const SlideshowButton = styled(ActionButton)`
  background-color: #f39c12;

  &:hover,
  &:focus {
    background-color: #e67e22;
  }
`;

const QuizButton = styled(ActionButton)`
  background-color: #3498db;

  &:hover,
  &:focus {
    background-color: #2980b9;
  }
`;

const AssessmentButton = styled(ActionButton)`
  background-color: #9b59b6;

  &:hover,
  &:focus {
    background-color: #8e44ad;
  }
`;

const Footer = styled.footer`
  background-color: #333333;
  color: white;
  padding: 2rem 1rem;
  margin-top: auto;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
`;

const FooterNav = styled.nav`
  display: flex;
  gap: 0.5rem;
`;

const FooterLink = styled.a`
  color: #4a90e2;
  text-decoration: underline;
  font-weight: 600;
  font-size: 0.9rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: all 0.3s ease;

  &:hover,
  &:focus {
    color: #2980b9;
    text-decoration: none;
    background-color: rgba(74, 144, 226, 0.1);
  }
`;

const MarkdownContent = styled.div`
  line-height: 1.6;
  color: #333;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Montserrat", sans-serif;
    margin-top: 1.5em;
    margin-bottom: 0.5em;
  }

  h1 {
    font-size: 2em;
    color: #2c3e50;
    border-bottom: 2px solid #3498db;
    padding-bottom: 0.3em;
  }

  h2 {
    font-size: 1.5em;
    color: #34495e;
    border-bottom: 1px solid #bdc3c7;
    padding-bottom: 0.3em;
  }

  h3 {
    font-size: 1.25em;
    color: #4a90e2;
  }

  p {
    margin-bottom: 1.2em;
  }

  ul,
  ol {
    margin-bottom: 1.2em;
    padding-left: 2em;
  }

  li {
    margin-bottom: 0.5em;
  }

  code {
    background-color: #f0f0f0;
    padding: 0.2em 0.4em;
    border-radius: 3px;
    font-family: monospace;
  }

  pre {
    background-color: #f0f0f0;
    padding: 1em;
    border-radius: 5px;
    overflow-x: auto;
  }

  a {
    color: #4a90e2;
    text-decoration: underline;
    font-weight: bold;
    transition: color 0.3s ease;

    &:hover,
    &:focus {
      color: #2980b9;
      text-decoration: none;
    }
  }
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const CollapsibleSection = styled.div`
  margin-bottom: 1rem;
`;

const SectionHeader = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: none;
  border: none;
  padding: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  color: #4a90e2;
`;

const ToggleConceptsButton = styled.button`
  position: fixed;
  top: 80px;
  left: 20px;
  background-color: #2ecc71;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;

  &:hover {
    background-color: #27ae60;
    transform: scale(1.1);
  }
`;

const DomainKeyConcepts: React.FC = () => {
  const { domainId } = useParams<{ domainId: string }>();
  const navigate = useNavigate();
  const [domain, setDomain] = useState<Domain | null>(null);
  const [keyConcepts, setKeyConcepts] = useState<KeyConcept[]>([]);
  const [selectedConcept, setSelectedConcept] = useState<KeyConcept | null>(
    null,
  );
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  const [searchTerm, setSearchTerm] = useState("");
  const [collapsedSections, setCollapsedSections] = useState<{
    [key: string]: boolean;
  }>({});
  const [showConcepts, setShowConcepts] = useState(true);
  const mainContentRef = useRef<HTMLDivElement>(null);

  const handleSlideshowClick = () => {
    if (selectedConcept) {
      navigate(`/slideshow/${selectedConcept.id}`);
    }
  };

  const handleQuizClick = () => {
    if (selectedConcept) {
      navigate(`/quiz/take?concept_id=${selectedConcept.id}&num_questions=10`);
    }
  };

  const handleAssessmentClick = () => {
    if (selectedConcept) {
      navigate(`/quiz/take?concept_id=${selectedConcept.id}&num_questions=25`);
    }
  };

  useEffect(() => {
    const fetchDomainKeyConcepts = async () => {
      try {
        const response = await api.get(`/domains/${domainId}/key_concepts`);
        setDomain(response.data.domain);
        const sortedConcepts = response.data.key_concepts.sort(
          (a: KeyConcept, b: KeyConcept) => a.name.localeCompare(b.name),
        );
        setKeyConcepts(sortedConcepts);

        const sections = Array.from<string>(
          new Set(
            sortedConcepts.map((concept: KeyConcept) =>
              concept.name[0].toUpperCase(),
            ),
          ),
        );
        const expandedSections = sections.reduce<{ [key: string]: boolean }>(
          (acc, section) => {
            acc[section] = false;
            return acc;
          },
          {},
        );
        setCollapsedSections(expandedSections);
      } catch (error) {
        console.error("Error fetching domain key concepts:", error);
        // Check if it's an authentication error
        if (axios.isAxiosError(error) && error.response?.status === 401) {
          navigate("/login");
        }
      }
    };

    fetchDomainKeyConcepts();

    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [domainId, navigate]);

  const handleConceptClick = (concept: KeyConcept) => {
    setSelectedConcept(concept);
    if (showConcepts) {
      setTimeout(() => {
        setShowConcepts(false);
        if (mainContentRef.current) {
          mainContentRef.current.scrollIntoView({ behavior: "smooth" });
        }
      }, 300);
    } else {
      if (mainContentRef.current) {
        mainContentRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const toggleSection = (section: string) => {
    setCollapsedSections((prev) => ({ ...prev, [section]: !prev[section] }));
  };

  const toggleConceptsVisibility = () => {
    setShowConcepts(!showConcepts);
  };

  const filteredKeyConcepts = keyConcepts.filter((concept) =>
    concept.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const groupedConcepts = filteredKeyConcepts.reduce<{
    [key: string]: KeyConcept[];
  }>((acc, concept) => {
    const firstLetter = concept.name[0].toUpperCase();
    if (!acc[firstLetter]) {
      acc[firstLetter] = [];
    }
    acc[firstLetter].push(concept);
    return acc;
  }, {});

  return (
    <DomainKeyConceptsWrapper>
      <Header />
      <ToggleConceptsButton onClick={toggleConceptsVisibility}>
        {showConcepts ? <FaChevronLeft /> : <FaChevronRight />}
      </ToggleConceptsButton>
      <Main ref={mainContentRef}>
        <ContentGrid showConcepts={showConcepts}>
          {showConcepts && (
            <Card>
              <CardTitle>
                <FaBook aria-hidden="true" /> Key Concepts
              </CardTitle>
              <SearchInput
                type="text"
                placeholder="Search concepts..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
              {Object.entries(groupedConcepts)
                .sort()
                .map(([section, concepts]) => (
                  <CollapsibleSection key={section}>
                    <SectionHeader onClick={() => toggleSection(section)}>
                      {section}
                      {collapsedSections[section] ? (
                        <FaChevronDown />
                      ) : (
                        <FaChevronUp />
                      )}
                    </SectionHeader>
                    {!collapsedSections[section] && (
                      <ConceptList>
                        {concepts.map((concept) => (
                          <ConceptListItem key={concept.id}>
                            <ConceptButton
                              onClick={() => handleConceptClick(concept)}
                              aria-pressed={selectedConcept?.id === concept.id}
                            >
                              {concept.name}
                            </ConceptButton>
                          </ConceptListItem>
                        ))}
                      </ConceptList>
                    )}
                  </CollapsibleSection>
                ))}
            </Card>
          )}
          <ContentWrapper>
            <ButtonContainer>
              {selectedConcept && (
                <>
                  <SlideshowButton onClick={handleSlideshowClick}>
                    <FaLightbulb /> Slides
                  </SlideshowButton>
                  <QuizButton onClick={handleQuizClick}>
                    <FaQuestionCircle /> Quiz
                  </QuizButton>
                  <AssessmentButton onClick={handleAssessmentClick}>
                    <FaClipboardCheck /> Assessment
                  </AssessmentButton>
                </>
              )}
            </ButtonContainer>
            {selectedConcept ? (
              <div>
                <MarkdownContent>
                  <ReactMarkdown>{selectedConcept.description}</ReactMarkdown>
                </MarkdownContent>
              </div>
            ) : domain ? (
              <div>
                <MarkdownContent>
                  <ReactMarkdown>
                    {domain.intro_markdown || domain.description}
                  </ReactMarkdown>
                </MarkdownContent>
              </div>
            ) : (
              <p>Loading domain information...</p>
            )}
          </ContentWrapper>
        </ContentGrid>
      </Main>

      <Footer>
        <FooterContent>
          <p>&copy; 2024 Certit. All rights reserved.</p>
          <FooterNav>
            <FooterLink href="/about">About</FooterLink>
            <FooterLink href="#contact">Contact</FooterLink>
            <FooterLink href="#privacy">Privacy</FooterLink>
            <FooterLink href="#terms">ToS</FooterLink>
          </FooterNav>
        </FooterContent>
      </Footer>
    </DomainKeyConceptsWrapper>
  );
};

export default DomainKeyConcepts;
