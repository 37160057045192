// src/config.ts

const config = {
  API_URL:
    process.env.REACT_APP_API_URL ||
    (process.env.NODE_ENV === "development"
      ? "http://localhost:5000/api"
      : "https://www.certit.ai/api"),
};

export default config;
