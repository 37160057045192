import React from "react";

const Footer: React.FC = () => {
  return (
    <footer
      style={{
        backgroundColor: "#333333",
        color: "white",
        padding: "2rem 1rem",
        marginTop: "2rem",
      }}
    >
      <div
        style={{
          maxWidth: "1200px",
          margin: "0 auto",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <p>&copy; 2025 Certit.ai All rights reserved.</p>
        <nav>
          <ul
            style={{
              display: "flex",
              listStyle: "none",
              gap: "1rem",
              margin: 0,
              padding: 0,
            }}
          >
            <li>
              <a
                href="/about"
                style={{ color: "white", textDecoration: "none" }}
              >
                About Us
              </a>
            </li>
            <li>
              <a
                href="#contact"
                style={{ color: "white", textDecoration: "none" }}
              >
                Contact
              </a>
            </li>
            <li>
              <a
                href="#privacy"
                style={{ color: "white", textDecoration: "none" }}
              >
                Privacy Policy
              </a>
            </li>
            <li>
              <a
                href="#terms"
                style={{ color: "white", textDecoration: "none" }}
              >
                Terms of Service
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;
