import React, { useState, useEffect } from "react";
import api from "../api/axios";
import config from "../config";

// Example list of certifications (could be fetched from an API or stored statically)
const certifications = [
  { id: 1, name: "CompTIA Security+" },
  { id: 2, name: "Cisco CCNA" },
  { id: 3, name: "CISSP" },
  // ... add other certifications
];

// Define the structure of a leaderboard entry
interface LeaderboardEntry {
  user_id: number;
  username: string;
  average_score: number;
  quizzes_taken: number;
}

const Leaderboard = () => {
  const [leaderboard, setLeaderboard] = useState<LeaderboardEntry[]>([]);
  const [certId, setCertId] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // Specify that certIdParam is a string.
  const fetchLeaderboard = async (certIdParam: string) => {
    setLoading(true);
    setError("");
    try {
      const response = await api.get("/leaderboard/public", {
        params: certIdParam ? { cert_id: certIdParam } : {},
      });
      setLeaderboard(response.data.leaderboard);
    } catch (err) {
      console.error(err);
      setError("Failed to fetch leaderboard");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLeaderboard(certId);
  }, [certId]);

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4 text-center">Leaderboard</h1>
      <div className="mb-6 text-center">
        <label htmlFor="certSelect" className="mr-2 font-semibold">
          Filter by Certification:
        </label>
        <select
          id="certSelect"
          value={certId}
          onChange={(e) => setCertId(e.target.value)}
          className="border rounded p-2"
        >
          <option value="">All Certifications</option>
          {certifications.map((cert) => (
            <option key={cert.id} value={cert.id}>
              {cert.name}
            </option>
          ))}
        </select>
      </div>
      {loading && <p className="text-center">Loading leaderboard...</p>}
      {error && <p className="text-center text-red-500">{error}</p>}
      {!loading && !error && leaderboard.length > 0 && (
        <table className="min-w-full bg-white shadow-md rounded">
          <thead>
            <tr>
              <th className="px-4 py-2 border">Rank</th>
              <th className="px-4 py-2 border">User</th>
              <th className="px-4 py-2 border">Avg. Score (%)</th>
              <th className="px-4 py-2 border">Quizzes Taken</th>
            </tr>
          </thead>
          <tbody>
            {leaderboard.map((player, index) => (
              <tr key={player.user_id} className="text-center">
                <td className="px-4 py-2 border">{index + 1}</td>
                <td className="px-4 py-2 border">{player.username}</td>
                <td className="px-4 py-2 border">{player.average_score}</td>
                <td className="px-4 py-2 border">{player.quizzes_taken}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {!loading && leaderboard.length === 0 && (
        <p className="text-center">No leaderboard data available.</p>
      )}
    </div>
  );
};

export default Leaderboard;
