import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import api from "../api/axios";
import CertificationPath from "./CertificationPath";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";

interface Certification {
  id: number;
  name: string;
  description: string;
  icon: string;
  color: string;
}

const AllCertifications: React.FC = () => {
  const [certifications, setCertifications] = useState<Certification[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchAllCertifications();
  }, []);

  const fetchAllCertifications = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.get("/quiz/certifications");
      setCertifications(response.data);
    } catch (error) {
      console.error("Error fetching certifications:", error);
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        navigate("/login");
      } else {
        setError("Failed to fetch certifications. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="flex flex-col min-h-screen"
      style={{
        fontFamily: "Open Sans, sans-serif",
        color: "#333333",
        backgroundColor: "#F8F8F8",
      }}
    >
      <Header />
      <main className="flex-grow max-w-6xl mx-auto px-4 py-4 w-full">
        <h1 className="text-3xl font-bold mb-6">All Certifications</h1>
        <CertificationPath
          certifications={certifications}
          loading={loading}
          error={error}
          setCurrentStep={() => {}}
        />
      </main>
      <Footer />
    </div>
  );
};

export default AllCertifications;
