import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import config from "../config";
import ReactMarkdown from "react-markdown";
import Header from "./Header";
import {
  FaBook,
  FaLightbulb,
  FaQuestionCircle,
  FaClipboardCheck,
} from "react-icons/fa";
import { KeyConcept } from "../types";

const KeyConceptViewWrapper = styled.div`
  font-family: "Open Sans", sans-serif;
  color: #333333;
  background-color: #f8f8f8;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Main = styled.main`
  max-width: 1200px;
  margin: 1rem auto;
  padding: 0 1rem;
  flex: 1;
`;

const ContentWrapper = styled.div`
  background-color: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 1rem;
  gap: 0.5rem;
`;

const ActionButton = styled.button`
  font-family: "Montserrat", sans-serif;
  color: white;
  font-size: 0.875rem;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none; // Add this
  text-decoration: none;

  &:hover,
  &:focus {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  svg {
    margin-right: 0.5rem;
  }
`;

const SlideshowButton = styled(ActionButton)`
  background-color: #f39c12;

  &:hover,
  &:focus {
    background-color: #e67e22;
  }
`;

const QuizButton = styled(ActionButton)`
  background-color: #3498db;

  &:hover,
  &:focus {
    background-color: #2980b9;
  }
`;

const AssessmentButton = styled(ActionButton)`
  background-color: #9b59b6;

  &:hover,
  &:focus {
    background-color: #8e44ad;
  }
`;

const MarkdownContent = styled.div`
  line-height: 1.6;
  color: #333;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Montserrat", sans-serif;
    margin-top: 1.5em;
    margin-bottom: 0.5em;
  }

  h1 {
    font-size: 2em;
    color: #2c3e50;
    border-bottom: 2px solid #3498db;
    padding-bottom: 0.3em;
  }

  h2 {
    font-size: 1.5em;
    color: #34495e;
    border-bottom: 1px solid #bdc3c7;
    padding-bottom: 0.3em;
  }

  h3 {
    font-size: 1.25em;
    color: #4a90e2;
  }

  p {
    margin-bottom: 1.2em;
  }

  ul,
  ol {
    margin-bottom: 1.2em;
    padding-left: 2em;
  }

  li {
    margin-bottom: 0.5em;
  }

  code {
    background-color: #f0f0f0;
    padding: 0.2em 0.4em;
    border-radius: 3px;
    font-family: monospace;
  }

  pre {
    background-color: #f0f0f0;
    padding: 1em;
    border-radius: 5px;
    overflow-x: auto;
  }

  a {
    color: #4a90e2;
    text-decoration: underline;
    font-weight: bold;
    transition: color 0.3s ease;

    &:hover,
    &:focus {
      color: #2980b9;
      text-decoration: none;
    }
  }
`;

const Footer = styled.footer`
  background-color: #333333;
  color: white;
  padding: 2rem 1rem;
  margin-top: auto;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
`;

const FooterNav = styled.nav`
  display: flex;
  gap: 0.5rem;
`;

const FooterLink = styled.a`
  color: #4a90e2;
  text-decoration: underline;
  font-weight: 600;
  font-size: 0.9rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: all 0.3s ease;

  &:hover,
  &:focus {
    color: #2980b9;
    text-decoration: none;
    background-color: rgba(74, 144, 226, 0.1);
  }
`;

const KeyConceptView: React.FC = () => {
  const { conceptId } = useParams<{ conceptId: string }>();
  const navigate = useNavigate(); // Add this
  const [keyConcept, setKeyConcept] = useState<KeyConcept | null>(null);

  // Add navigation handler functions
  const handleSlideshowClick = () => {
    if (keyConcept) {
      navigate(`/slideshow/${keyConcept.id}`);
    }
  };

  const handleQuizClick = () => {
    if (keyConcept) {
      navigate(`/quiz/take?concept_id=${keyConcept.id}&num_questions=10`);
    }
  };

  const handleAssessmentClick = () => {
    if (keyConcept) {
      navigate(`/quiz/take?concept_id=${keyConcept.id}&num_questions=25`);
    }
  };

  // Add authentication error handling to the useEffect
  useEffect(() => {
    const fetchKeyConcept = async () => {
      try {
        const response = await axios.get(
          `${config.API_URL}/key-concepts/${conceptId}`,
        );
        setKeyConcept(response.data);
      } catch (error) {
        console.error("Error fetching key concept:", error);
        // Check if it's an authentication error
        if (axios.isAxiosError(error) && error.response?.status === 401) {
          navigate("/login");
        }
      }
    };
    fetchKeyConcept();
  }, [conceptId, navigate]);

  return (
    <KeyConceptViewWrapper>
      <Header />
      <Main>
        <ContentWrapper>
          <ButtonContainer>
            {keyConcept && (
              <>
                <SlideshowButton onClick={handleSlideshowClick}>
                  <FaLightbulb /> Slides
                </SlideshowButton>
                <QuizButton onClick={handleQuizClick}>
                  <FaQuestionCircle /> Quiz
                </QuizButton>
                <AssessmentButton onClick={handleAssessmentClick}>
                  <FaClipboardCheck /> Assessment
                </AssessmentButton>
              </>
            )}
          </ButtonContainer>
          {keyConcept ? (
            <MarkdownContent>
              <ReactMarkdown>{keyConcept.description}</ReactMarkdown>
            </MarkdownContent>
          ) : (
            <p>Loading key concept information...</p>
          )}
        </ContentWrapper>
      </Main>

      <Footer>
        <FooterContent>
          <p>&copy; 2024 Certit. All rights reserved.</p>
          <FooterNav>
            <FooterLink href="/about">About</FooterLink>
            <FooterLink href="#contact">Contact</FooterLink>
            <FooterLink href="#privacy">Privacy</FooterLink>
            <FooterLink href="#terms">ToS</FooterLink>
          </FooterNav>
        </FooterContent>
      </Footer>
    </KeyConceptViewWrapper>
  );
};

export default KeyConceptView;
